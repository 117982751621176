<template>
  <div class="public-box">
    <el-form :inline="true">
      <el-form-item>
        <MyInput v-model="formInline.merchants_stall_name" placeholder="请输入商户姓名">
          <template slot="pre">商户姓名:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <MyInput v-model="formInline.merchants_number" placeholder="请输入商户号">
          <template slot="pre">商户号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <MyInput v-model="formInline.stall_number" placeholder="请输入商铺号">
          <template slot="pre">商铺号:</template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="time" @change="timeChange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="daterange"
          start-placeholder="缴费开始时间" end-placeholder="缴费结束时间"></el-date-picker>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="formInline.bill_status" clearable @change="statusChange" placeholder>
          <el-option :value="0" label="未完成缴费"></el-option>
          <el-option :value="10" label="已完成缴费"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-select v-model="formInline.bill_status" clearable placeholder>
          <el-option :value="0" label="未完成缴费"></el-option>
          <el-option :value="10" label="已完成缴费"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <MyButton @click="currentChange(1)">
          <span>搜索</span>
          <template slot="preImage">
            <img src="../../../unit/img/search.png" alt />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>
    <el-form :inline="true">
      <el-form-item>
        <MyButton :accessId="76048" @click="settingDay">
          <span>设置</span>
          <template slot="preImage">
            <img src="../../../unit/img/st.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton :accessId="77395" @click="basebillExport">
          导出
          <template slot="preImage">
            <img src="../../../unit/img/down.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <!-- <el-form-item style="width: 50px"> -->
      <div class="ysall">
        <span>应收总金额:</span>
        <span>{{ confirmTotal }}</span>
      </div>
      <!-- </el-form-item> -->
    </el-form>
    <div class="public-table">
      <el-table :data="dataList" height="90%" :span-method="arraySpanMethod" :row-style="rowstyle" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }" v-loading="loading">
        <el-table-column label="商户姓名" prop="merchants_stall_name"></el-table-column>
        <el-table-column label="商户号" prop="merchants_number"></el-table-column>
        <el-table-column label="合同编码" show-overflow-tooltip width="230px" prop="contract_no"></el-table-column>
        <el-table-column label="商铺号" show-overflow-tooltip width="200px" prop>
          <template slot-scope="scope">
            <span>{{ scope.row.merchantsStallNumber.join(",") }}</span>
          </template>
        </el-table-column>
        <el-table-column label="合同总费用" prop="owe_price"></el-table-column>
        <el-table-column label="欠费时间" prop="first_fee_time">
          <template slot-scope="scope">
            <span v-if="scope.row.first_fee_time">{{
              scope.row.first_fee_time
            }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column label="基础费用欠费金额(元)" prop="first_fee_price"></el-table-column>

        <el-table-column width="200px" label="下次约定缴费时间">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_confirm_time">{{
              scope.row.pay_confirm_time
            }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column label="应缴金额(元)" prop="contract_pay_price"></el-table-column>
        <el-table-column :label="item + '应缴金额(元)'" v-for="(item, index) in fessArr" :key="index">
          <template #default="{ row }">
            <span>{{ setFeesvalue(item, row.pay_list39) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="应缴总金额(元)" prop="sum"></el-table-column>
        <el-table-column label="合同状态" prop="differ_day">
          <template slot-scope="scope">
            {{ scope.row.contract_status_name }}
            <!-- <span v-if="scope.row.differ_day < differDay">即将到期</span>
            <span v-else>正常</span> -->
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <pop tips="详情" :accessId="77692" @myclick="godetail(scope.row)">
              <div class="slot-box">
                <img class="icon" src="../../../unit/img/xq.png" alt />
              </div>
            </pop>
          </template>
        </el-table-column>
      </el-table>
      <div style="color: red; margin-top: 5px">
        注:基本账单数据来源于商户合同
      </div>
      <div class="public-page">
        <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
          :page-size="formInline.pageSize" :current-page="formInline.pageNum"
          @current-change="currentChange"></el-pagination>
      </div>
    </div>
    <el-dialog title="设置" width="880px" :visible.sync="setVisible" @close="close" :close-on-click-modal="false">
      <el-form :model="ruleForm" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="默认显示:">
          <el-input class="public-input" v-model="ruleForm.values.day" placeholder="请输入天数" clearable></el-input>
          <span style="marginleft: 10px">天内应缴费商户信息</span>
        </el-form-item>
        <el-form-item>
          <div style="margin-top: 5px; font-size: 12px; color: #f56c6c">
            注：必须为整数
          </div>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton @click="setVisible = false">取消</MyButton>
        <MyButton type="primary" left @click="confirm">确认</MyButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "basebill",
  data () {
    return {
      dataList: [],
      formInline: {
        merchants_stall_name: "",
        merchants_number: "",
        stall_number: "",
        beginTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10,
        bill_status: 0,
      },
      time: [],
      total: 0,
      setVisible: false,
      ruleForm: {
        values: {
          day: "",
        },
        describe: "默认显示多少天缴费信息",
        key: "showDayCostMsg",
      },
      confirmTotal: 0,
      differDay: 0,
      loading: false,
      fessArr: [],
    };
  },
  created () {
    this.getDiffer();
    this.getList();
    this.getMarketConfirmTotal();
  },
  methods: {
    getDiffer () {
      this.$request
        .HttpGet("/setting/edit", { key: "contractWarningSetting" })
        .then((res) => {
          if (res.data && res.data.values) {
            this.differDay = res.data.values.surplus_day || 0;
          }
        });
    },
    getMarketConfirmTotal () {
      this.$request
        .HttpGet("/bill/marketConfirmTotal", {
          beginTime: this.formInline.beginTime,
          endTime: this.formInline.endTime,
        })
        .then((res) => {
          this.confirmTotal = res.data || 0;
        });
    },
    rowstyle ({ row }) {
      if (row.differ_day < this.differDay) {
        return {
          color: "#F56C6C",
        };
      } else {
        return "";
      }
    },
    basebillExport () {
      console.log([...this.fessArr]);
      this.formInline.header_names = [...this.fessArr];
      this.$request
        .HttpPost("/merchants/merchantsListExport", this.formInline, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = objectUrl;
          link.setAttribute("download", "基本账单信息.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        });
    },
    close () {
      this.ruleForm = {
        values: {
          day: "",
        },
        describe: "默认显示多少天缴费信息",
        key: "showDayCostMsg",
      };
    },
    settingDay () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "showDayCostMsg",
        })
        .then((res) => {
          if (res.data) {
            this.ruleForm = res.data;
          }
          this.setVisible = true;
        });
    },
    confirm () {
      this.$request.HttpPost("/setting/add", this.ruleForm).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.setVisible = false;
        this.getList();
      });
    },
    timeChange (date) {
      if (date) {
        this.formInline.beginTime = date[0];
        this.formInline.endTime = date[1];
      } else {
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      }
    },
    arraySpanMethod ({ row, columnIndex, column }) {
      let index = -8;
      if (column.label == "操作") {
        index = columnIndex;
      }
      if (columnIndex == 0 || columnIndex == 1 || columnIndex == index) {
        if (row.rowspan > 0) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        }
        return [0, 0];
      }
    },
    getList () {
      this.loading = true;
      this.dataList = [];
      let fessArr = [];
      this.$request
        .HttpGet("/merchants/merchantsList", this.formInline)
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.list) {
            let dateArr = [];
            res.data.list.forEach((item) => {
              if (item.merchants_contract_list) {
                item.merchants_contract_list.forEach((list, j) => {
                  if (list.contract_bill_list) {
                    item.confirm_time = list.contract_bill_list.confirm_time;
                  }
                  list.merchantsStallNumber = [];
                  if (list.merchants_stall_list) {
                    list.merchants_stall_list.forEach((bill) => {
                      list.merchantsStallNumber.push(
                        bill.merchants_stall_number
                      );
                    });
                  }

                  if (list.pay_list39) {
                    list.pay_list39.forEach((item) => {
                      fessArr.push(item.setting_fee_name);
                    });
                  }
                  if (j == 0) {
                    dateArr.push({
                      ...item,
                      ...list,
                      merchants_number: item.merchants_number,
                      merchants_stall_name: item.merchants_stall_name,
                      rowspan: item.merchants_contract_list.length,
                      merchants_id: item.merchants_id,
                    });
                  } else {
                    dateArr.push({
                      ...item,
                      ...list,
                      merchants_number: item.merchants_number,
                      merchants_stall_name: item.merchants_stall_name,
                      merchants_id: item.merchants_id,
                    });
                  }
                });
              }
            });
            console.log(fessArr);
            this.fessArr = new Set([...fessArr]);
            this.dataList = dateArr;
            console.log(this.dataList);
            console.log(this.fessArr);
          }
          this.total = res.data.total;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.dataList = [];
        });
    },
    setFeesvalue (name, arr) {
      if (!Array.isArray(arr)) return 0;
      let value = arr.find((item) => {
        return item.setting_fee_name == name;
      });
      return value ? value.old_money : 0;
    },
    godetail (row) {
      this.$openNewTab(
        `/charge/merchantbill/basebilldetail?merchants_id=${row.merchants_id}&bill_key=10`
      );
      // this.$router.push({
      //   path: '',
      //   query: {
      //     merchants_id: row.merchants_id,
      //     bill_key:10
      //   }
      // })
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
      this.getMarketConfirmTotal();
    },
    statusChange (e) {
      if (e || e == 0) {
        this.formInline.merchants_stall_name = "";
        this.formInline.merchants_number = "";
        this.formInline.stall_number = "";
        this.formInline.beginTime = "";
        this.formInline.endTime = "";
      }
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.ysall {
  display: inline-block;
  min-width: 160px;
  height: 32px;
  background: #02ce80;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 32px;

  span {
    &:nth-child(2) {
      font-weight: bold;
      margin-left: 12px;
    }
  }
}
</style>
